import React, { useState } from "react";
import Ticker from "react-ticker";
import styled from "styled-components";
import PageVisibility from "react-page-visibility";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";

/**
 *
 * @param {Object} props
 * @param {Number} props.tickerSpeed
 * @param {String} props.direction
 * @param {any} props.offset
 * @param {any} props.children
 */
export default function Tick({
  tickerSpeed,
  direction,
  offset,
  children,
  ...rest
}) {
  const [pageIsVisible, setPageIsVisible] = useState(true);
  const reduceMotion = useReducedMotion();

  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible);
  };

  const speed = tickerSpeed ? tickerSpeed : 10;

  return (
    <TickerWrapper {...rest}>
      <PageVisibility onChange={handleVisibilityChange}>
        <AnimatePresence>
          {pageIsVisible && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "100%", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <Ticker
                move={true}
                direction={direction ? direction : "toRight"}
                offset={offset}
                speed={reduceMotion ? 2 : speed}
              >
                {children}
              </Ticker>
            </motion.div>
          )}
        </AnimatePresence>
      </PageVisibility>
    </TickerWrapper>
  );
}

const TickerWrapper = styled.div`
  .ticker {
    width: 107vw;
    transform: translate3d(-7vw, 0, 0);
    overflow: visible !important;
    display: inline-flex;
    user-select: none;
  }

  .ticker__element {
    display: inline-flex;
    flex-wrap: nowrap;
    width: fit-content;
    white-space: pre;
    will-change: transform;
  }
`;
