import React from "react";
import Tick from "./tick";
import { isEvenOdd } from "../functions/util";
import styled from "styled-components";

const TickerWrapper = styled.section`
  margin-top: 6vw;
  margin-bottom: 6vw;
`;

export function TickerServiceSection({ services }) {
  return (
    <TickerWrapper>
      <Tick tickerSpeed={5}>
        {() => (
          <>
            {services.map(({ service }, i) => {
              const isSerif = isEvenOdd(i) === "even" ? "serif" : "sans";
              return (
                <p key={i} className={`h2 peach ${isSerif}`}>
                  {service.text}
                  <span className="sans">{" + "}</span>
                </p>
              );
            })}
          </>
        )}
      </Tick>
    </TickerWrapper>
  );
}
