import React from "react";
import Box from "./motion/motion-box";
import ImageBox from "./motion/motion-box/image-box";
import styled from "styled-components";
import { hoverChange, hoverCustomProperty } from "../functions/util";

const TeamItem = styled(Box)`
  margin-right: 10vw;
  margin-bottom: 8vw;
  position: relative;

  @media (hover: hover) {
    border-radius: 0.25rem;
    background: ${({ theme }) => theme.colors.black};

    &:hover {
      .portrait .gatsby-image-wrapper {
        transform: translate3d(1rem, -3.5rem, 0);

        @media (hover: hover) {
          filter: grayscale(0);
        }
      }

      .job_title {
        opacity: 1;
      }
    }
  }

  &:nth-child(even) {
    margin-left: 10vw;
  }

  @media ${({ theme }) => theme.device.tablet} {
    &:nth-child(even) {
      margin-left: unset;
    }

    &:nth-child(3n + 3),
    &:nth-child(4n + 4) {
      margin-right: 0;
      margin-left: 10vw;
    }
  }

  .portrait .gatsby-image-wrapper {
    transform: none;
    will-change: transform;
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      filter ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css};

    @media (hover: hover) {
      filter: grayscale(1);
    }
  }
`;
const TeamItemContent = styled.div`
  margin-top: 1rem;

  @media (hover: hover) {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: -1;

    .name {
      display: none;
    }

    .job_title {
      opacity: 0;
      transition: opacity ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css};
    }
  }

  p {
    margin-bottom: 0;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.peach};
  }
`;
export function TeamMember({ portrait, name, job_title }) {
  return (
    <TeamItem
      onMouseEnter={() => {
        hoverChange("hover-team-member");
        hoverCustomProperty("custom-content", name.text);
      }}
      onMouseLeave={() => {
        hoverChange(null);
        hoverCustomProperty(null);
      }}
    >
      <ImageBox className="portrait" fluid={portrait.fluid} />
      <TeamItemContent>
        <p className="name">{name.text}</p>
        <p className="job_title">{job_title.text}</p>
      </TeamItemContent>
    </TeamItem>
  );
}
