import React from "react";
import MotionHeadline from "./motion/motion-headline";
import { Wrapper } from "./globals/wrappers";
import styled from "styled-components";
import MotionScrollTransform from "./motion/motion-scroll-transform";
import { m } from "framer-motion";
import ImageBox from "./motion/motion-box/image-box";
import { useWindowSize } from "./motion/motion-scroll-transform/index";
import ColumnWrapper from "./globals/column";

const LocationGrid = styled.div`
  margin: 4vw auto;
  font-family: "Orbikular", serif;

  em {
    font-family: "Aeonik", sans-serif;
  }
`;

const BlockWrap = styled(m.div)`
  display: inline-flex;
  flex-direction: row;
`;

const Block = styled(ImageBox)`
  width: calc(50vw * ${({ aspect }) => aspect});
  height: 50vw;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

function MotionSection({
  data: { studio_image_gallery },
  useTransform,
  yTransform,
}) {
  const { width } = useWindowSize();
  const [elWidth, setElWidth] = React.useState(0);
  const ref = React.useRef();

  React.useEffect(() => {
    const w = ref?.current?.offsetWidth - width;
    setElWidth(-w);
  }, [ref, width]);

  const x = useTransform(yTransform, [0, 0.25, 0.95], [200, 0, elWidth]);
  const opacity = useTransform(yTransform, [0, 0.25, 1], [0, 1, 1]);

  return (
    <>
      <BlockWrap
        ref={ref}
        style={{
          x,
          opacity,
        }}
      >
        {studio_image_gallery.map(({ image }, i) => (
          <Block key={i} fluid={image.fluid} aspect={image.fluid.aspectRatio} />
        ))}
      </BlockWrap>
    </>
  );
}

export function LocationSection({ data }) {
  return (
    <Wrapper>
      <MotionHeadline
        text={data.studio_image_title}
        className="h6 section-title"
      />

      <ColumnWrapper start={2} number={13}>
        <LocationGrid>
          <MotionHeadline
            text={data.studio_image_headline.text}
            className="h1"
          />
        </LocationGrid>
      </ColumnWrapper>

      <MotionScrollTransform id="page-header" settings={{ offsetEnd: 50 }}>
        {(useTransform, yTransform, width) => (
          <>
            <MotionSection
              data={data}
              useTransform={useTransform}
              yTransform={yTransform}
              width={width}
            />
          </>
        )}
      </MotionScrollTransform>
    </Wrapper>
  );
}
