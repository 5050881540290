import React from "react";
import MotionHeadline from "./motion/motion-headline";
import { Wrapper } from "./globals/wrappers";
import styled from "styled-components";
import { TeamMember } from "./team-member";

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 6vw;
  margin-bottom: 6vw;

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;
export function TeamSection({ data }) {
  return (
    <Wrapper>
      <MotionHeadline text={data.team_title} className="h6 section-title" />
      <TeamGrid>
        {data.team_member.map(({ portrait, name, job_title }, i) => {
          return (
            <TeamMember
              key={i}
              portrait={portrait}
              name={name}
              job_title={job_title}
            />
          );
        })}
      </TeamGrid>
    </Wrapper>
  );
}
