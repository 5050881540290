import React from "react";
import { graphql } from "gatsby";

import { PageMarquee } from "../components/page-marquee";
import ColumnWrapper from "../components/globals/column";
import MotionHeadline from "../components/motion/motion-headline";
import { TickerServiceSection } from "../components/ticker-service-section";
import { TeamSection } from "../components/team-section";
import { LocationSection } from "../components/location-section";
import { ClientsSection } from "../components/clients-section";
import { ContactSection } from "../components/contact-section";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Studio({
  data: {
    prismicStudiopage: { data },
  },
}) {
  return (
    <>
      <PageMarquee
        headline={data.marquee_headline.text}
        image={{ fluid: data.marquee_image.fluid, alt: data.marquee_image.alt }}
      />
      <ColumnWrapper start={3} number={11}>
        <MotionHeadline
          text={data.services_headline.text}
          className="h2 serif"
        />
      </ColumnWrapper>
      <TickerServiceSection services={data.services_list} />
      <TeamSection data={data} />
      <LocationSection data={data} />
      {data.show_client_logos && <ClientsSection />}
      <ContactSection data={data} />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query PrismicStudiopage($uid: String!) {
    prismicStudiopage(uid: { eq: $uid }) {
      id
      data {
        title
        marquee_headline {
          text
        }
        marquee_image {
          alt
          copyright
          url
          fluid(maxWidth: 2000) {
            ...GatsbyPrismicImageFluid
            aspectRatio
          }
        }
        team_title
        team_member {
          portrait {
            alt
            copyright
            url
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
              aspectRatio
            }
          }
          name {
            text
          }
          job_title {
            text
          }
        }
        services_headline {
          text
        }
        services_list {
          service {
            text
          }
        }

        contact_title
        contact_text {
          text
          raw
          html
        }
        show_client_logos
        studio_image_gallery {
          image {
            fluid(maxWidth: 2000) {
              ...GatsbyPrismicImageFluid
              aspectRatio
            }
          }
        }
        studio_image_headline {
          text
          html
          raw
        }
        studio_image_title
      }
    }
  }
`;
